import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Form from '../../components/Form'
import './index.css'

import bigLogo from '../../images/big-logo.svg'

const ContactPage = () => (
    <Layout>
        <SEO title='Accountant Glen Burnie Maryland'/>
        <section>
            <div className='contact-page-header'>
                <div className='contact-page-header-left'>
                    <h1 style={{color: `#B62020`}}>CONTACT ME TODAY</h1>
                    <p>
                    Whether you are looking for tax advice, or accounting and bookkeeping services, it is my pleasure
                    to be at your service and am looking forward to creating a professional business relationship
                    that satisfies all of your accounting needs.
                    <br/>
                    <br/>
                    -Kevin Rich
                    </p>
                </div>
                <div className='contact-page-header-right'>
                    <img src={bigLogo} alt='accountant logo'/>
                </div>
            </div>
        </section>

        <section>
        <div className='section-column'>
            <h3 style={{color: `#B62020`}}>SERVING ANNE ARUNDEL COUNTY AND BEYOND</h3>
            <p>
            I am known for my client-first approach to accounting, which is why I prioritize consistent 
            communication with all of my clients.  When you contact me, I will set up a confidential, 
            obligation-free meeting with you at your earliest convenience to ensure that I can provide 
            services that match your expectations.  I seek to clearly understand your financial objectives, so 
            that I can provide accounting and tax solutions that will position you for success.  Whether 
            you need assistance today or advice for a goal ten years from now, I will cater my services to 
            suit your unique needs.
            <br/>
            <br/>
            <span style={{fontWeight: `bold`}}>Servicing the greater Baltimore metro area including:  Anne Arundel County, Prince George’s County, Howard County, Baltimore County, and Baltimore City</span>
            </p>
        </div>
        </section>

        <section>
            <div className='contact-section'>
                <div className='contact-section-top'>
                <p style={{margin: `0`}}>CONTACT</p>
                </div>
                <div className='contact-section-bottom'>
                <div className='contact-section-bottom__left'>
                    <p>
                    Contact me by phone at <span style={{color: `#B62020`}}>(410)271-8259</span> 
                    <br/>
                    <br/>
                    or
                    <br/>
                    <br/>
                    Fill out the contact form and I'll reach out to you as soon as possible.
                    </p>
                </div>
                <div className='contact-section-bottom__right'>
                    <Form />
                </div>
                </div>
            </div>
        </section>
    </Layout>
)

export default ContactPage